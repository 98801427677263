import React from 'react';
import { bool, func } from 'prop-types';
import loadable from '@loadable/component';
import LazyImage from '@components/Common/LazyImage';

import {
  Box,
  Button,
  Center,
  Text,
  Image,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
  Heading,
  AspectRatio,
} from '@chakra-ui/react';

import { BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_VIDEO_PREWEDDING } from '@/constants/feature-flags';
import { BRIDE_HASHTAG, PREWEDDING_EMBED, THE_BRIDE } from '@/constants';
import { ASSETS_FLOWER_LEFT, IMG_GALLERY_COVER } from '@/constants/assets';

import useLang from '@hooks/useLang';
import ImgLogo from '@/assets/icons/logo-invert.png';

import { photos as photosLive } from './api/photos-2';

import txt from './locales';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

function PhotoContent({ isOpen, onClose }) {
  const lang = useLang();

  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="500px" bgColor="bgSecondary">
        <ModalCloseButton color="transparent" {...BUTTON_PROPS} />
        <ModalBody width="100%" padding="0">
          <Box
            bgImage={`url(${IMG_GALLERY_COVER})`}
            height="80vh"
            bgSize="cover"
            bgPosition="center"
          />
          <Text textAlign="center" color="bgPrimary" transform="translateY(-80px)">
            {BRIDE_HASHTAG}
          </Text>
          {/* Images Cover */}
          <Box
            padding="32px"
            paddingTop="20px"
            textAlign="center"
            color="bgPrimary"
            fontFamily="body"
          >
            <Heading size="3xl" fontWeight="normal">
              About <br /> Us
            </Heading>
            <Text margin="8px">
            I found you without looking, and love you without trying
              <br /> 
              - Mark Anthony -
            </Text>
            <Box paddingTop="42px" marginTop="-42px">
              <LazyImage
                src={ASSETS_FLOWER_LEFT}
                height="200px"
                position="absolute"
                left="30px"
                filter="invert(1)"
                zIndex={1}
              />
              <Box marginTop="70px" position="relative" zIndex={2}>
                {isOpen && <LoadableGallerry data={photosLive} />}
              </Box>
              {ENABLE_VIDEO_PREWEDDING && (
                <Box marginTop="120px">
                  <AspectRatio marginTop="-100px" maxW="560px" ratio={16 / 9} boxShadow="xl">
                    <iframe
                      title={`Prewedding of ${THE_BRIDE}`}
                      src={PREWEDDING_EMBED}
                      allowFullScreen
                      loading="lazy"
                      style={{ borderRadius: '16px' }}
                    />
                  </AspectRatio>
                </Box>
              )}
            </Box>
          </Box>
          <Center>{false && <Image src={ImgLogo} maxW="100px" marginTop="16px" />}</Center>
          <Center padding="16px 0 82px 0">
            <Button
              size="md"
              color="white"
              bgColor="bgAlternative"
              boxShadow="md"
              fontWeight="normal"
              textTransform="uppercase"
              letterSpacing="2px"
              onClick={() => onClose()}
              fontFamily="body"
              {...BUTTON_PROPS}
            >
              {txt.back[lang]}
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;